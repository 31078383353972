<template>
  <div>
    <b-form  @submit="onSubmit" @reset="onReset" inline v-if="show">
      <!-- <b-form  inline>  -->
       <b-form-input v-model="phoneNumber" :state="phoneNumber_validation" id="phoneNumber" placeholder="請輸入手機號碼"></b-form-input>
      <b-form-invalid-feedback :state="phoneNumber_validation">
        
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="phoneNumber_validation">
       
      </b-form-valid-feedback>

       <b-input-group prepend="@" class="mb-2 mr-sm-2 mb-sm-0">
       <b-form-input v-model="password" :state="password_validation" type="password" id="password" placeholder="請輸入密碼"></b-form-input>
      <b-form-invalid-feedback :state="password_validation">
      
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="password_validation">
        
      </b-form-valid-feedback>
      </b-input-group>
       
       <b-button type="submit" variant="primary">登入網站</b-button>
      
     </b-form>
  </div>
  

   
       
     
     
</template>

<script>
//import Header from './Header.vue'

export default {
  name: 'CreateLogin',
  data(){
    return {
      password: '',
     
      phoneNumber: '',
      show: true
      
    }
  },
  
  methods:{
    onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      }
    ,onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
      //  username_validation: (state) => {
      //    if ( state )
      //     return this.username.length > 4 && this.username.length < 13
      //    else 
      //     return false;
      //  },
  
  
   ,keymonitor: function(event){
      this.username_error = '此為顯示在網站上的名稱，長度要兩個字以上，中英文皆可'
      console.log(event.target.name)
      // if (event.target.name == 'username'){
      //   this.username_validation(true)
      // }else{
      //    this.username_validation(false)
      // }
   }
  },
  computed: {
     username_validation () {
   
      return this.username.length > 4 && this.username.length < 13
    
   }
  }
 
  
}
</script>