<template>
  <div>
    <b-form  @submit="onSubmit" @reset="onReset" v-if="show">
      <b-form-input v-model="username"   v-on:keyup.self="keymonitor" :state="username_validation" name="username" placeholder="*網路名字(中/英)"></b-form-input>
      <b-form-invalid-feedback class="color-black"  :state="username_validation">
       {{ username_error }}
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="username_validation">
       
      </b-form-valid-feedback>

       <b-form-input v-model="phoneNumber" :state="phoneNumber_validation" id="phoneNumber" placeholder="*手機號碼(例如: 0934567890)"></b-form-input>
      <b-form-invalid-feedback :state="phoneNumber_validation">
        您的手機號碼，作為未來驗證或找回密碼用
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="phoneNumber_validation">
       
      </b-form-valid-feedback>

       <b-form-input v-model="password" :state="password_validation" id="password" type="password" placeholder="*密碼:可設定喜歡的英文單字(例如: rich)或英文+數字"></b-form-input>
      <b-form-invalid-feedback :state="password_validation">
       密碼長度至少為一個英文字母，英文數字共為4-10個字
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="password_validation">
        Looks Good.
      </b-form-valid-feedback>

       <b-form-input v-model="repassword" :state="repassword_validation" id="repassword" type="password" placeholder="*重複剛打得密碼"></b-form-input>
      <b-form-invalid-feedback :state="repassword_validation">
        與您之前打的密碼不符，請再重複打您剛設定的密碼
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="repassword_validation">
        Looks Good.
      </b-form-valid-feedback>

       <b-form-input v-model="answer" :state="answer_validation" id="answer" placeholder="*喜歡的食物?(作為找回密碼的提示，長度限制2-8個字)"></b-form-input>
      <b-form-invalid-feedback :state="answer_validation">
       作為找回密碼的提示，長度限制2-8個字
      </b-form-invalid-feedback>
      <b-form-valid-feedback :state="answer_validation">
        Looks Good.
      </b-form-valid-feedback>
       <b-button type="submit" variant="primary" block>送出註冊資訊</b-button>
   

      

     </b-form>
  </div>
    <!-- <form>
      <div class="row">
          <div class="form-group col-md-6">
              <label htmlFor="username">網路名字(中/英)</label>
              <input type="text" class="form-control red-focus" :state="validation" v-model="username" name="username" id="username" aria-describedby="usernameHelp" placeholder="*網路名字(中/英)" />
              <div id="usernameHelp">maximum length 30 characters</div>
            
               <b-form-invalid-feedback :state="validation">
                Your user ID must be 5-12 characters long.
              </b-form-invalid-feedback>
              <b-form-valid-feedback :state="validation">
                Looks Good.
              </b-form-valid-feedback>
          </div>
          <div class="form-group col-md-6">
              <label htmlFor="phoneNumber">手機號碼(例如: 0934567890)</label>
              <input type="tel" class="form-control" v-model="phoneNumber" name="phoneNumber" id="phoneNumber" placeholder="*手機號碼(例如: 0934567890)" />
          </div>
          
      </div>
      <div class="row">
           <div class="form-group col-md-6">
              <label htmlFor="password">喜歡的英文單字(例如: rich)或密碼(例如: rich123)</label>
              <input type="text" class="form-control" v-model="password" name="password" id="password" aria-describedby="passwordHelp" placeholder="*喜歡的英文單字(例如: rich)或密碼(例如: rich123)" />
          </div>
           <div class="form-group col-md-6">
              <label htmlFor="repassword">重複: 喜歡的英文單字(例如: rich)或密碼(例如: rich123)</label>
              <input type="text" class="form-control" v-model="repassword" name="repassword" id="repassword" aria-describedby="repasswordHelp" placeholder="*重複: 喜歡的英文單字(例如: rich)或密碼(例如: rich123)" />
          </div>
      </div>
     
      <div class="row">
         <div class="form-group col-md-6">
              <label htmlFor="birthday">生日8碼(例如: 19300303)</label>
              <input type="text" class="form-control" v-model="birthday" name="birthday" id="birthday" placeholder="生日8碼(例如: 19300303)" />
          </div>
           <div class="form-group col-md-6">
              <label htmlFor="liveSite">居住縣市(例如: 台南市)</label>
              <input type="tel" class="form-control" v-model="liveSite" name="liveSite" id="liveSite" placeholder="居住縣市(例如: 台南市)" />
          </div>
         
      </div>
       <div class="row">
          <div class="form-group col-md-12">
              <label htmlFor="dream">人生想完成的一件事？(中英10個字之內)：當作找回密碼</label>
              <input type="text" class="form-control" v-model="dream" name="dream" id="dream" aria-describedby="dreamHelp" placeholder="*人生想完成的一件事？(中英10個字之內)：當作找回密碼" />
          </div>
      </div>
     
      <button type="button" @click='createSign()' class="btn btn-danger">完成註冊</button>
       <hr>
        <div class="mt-2 color-red" variant="outline-primary">您的登入帳號: {{ phoneNumber }} ; 密碼為: {{ password }} </div>
      
    </form> -->

   
       
     
     
</template>

<script>
//import Header from './Header.vue'

export default {
  name: 'CreateSign',
  data(){
    return {
      password: '',
      repassword: '',
      phoneNumber: '',
      username: '',
      answer:'',
      username_error: ' ',
      show: true
      
    }
  },
 methods:{
    onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      }
    ,onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
   ,keymonitor: function(event){
      this.username_error = '此為顯示在網站上的名稱，長度要兩個字以上，中英文皆可'
      console.log(event.target.name)
      // if (event.target.name == 'username'){
      //   this.username_validation(true)
      // }else{
      //    this.username_validation(false)
      // }
   }
  },
  computed: {
     username_validation () {
   
      return this.username.length > 4 && this.username.length < 13
    
   }
  }
 
  
}
</script>