<template>
  <div> 




    <b-form  @submit="onSubmit" @reset="onReset" >
      <b-container fluid>
      <b-row class="mt-3">
       <b-form-input v-model="article"  type="text" :state="article_validation" 
       description="We will convert your name to lowercase instantly"
       id="article" placeholder="標題"></b-form-input>

        </b-row><b-row class="mt-3">
        <b-form-select v-model="selected" :options="options"></b-form-select>
        <div class="mt-3">Selected: <strong>{{ selected }}</strong></div>
        </b-row><b-row>
        <b-form-textarea
        id="textarea-rows"
        size="lg"
        placeholder="請輸入文章"
        rows="6"
      ></b-form-textarea>
       </b-row><b-row>


        <label for="tags-separators">輸入1~4個代表文章的標籤，使用空白或逗號當斷點</label>
        <b-form-tags
        input-id="tags-separators"
        v-model="value"
        separator=" ,;"
        placeholder="Enter new tags separated by space, comma or semicolon"
        no-add-on-enter
        ></b-form-tags>

        </b-row><b-row>
         <b-button type="submit" variant="primary" block>新增文章 </b-button>
        </b-row>
        </b-container>
     </b-form>

      
  </div>
  

   
       
     
     
</template>

<script>
//import Header from './Header.vue'

export default {
  name: 'CreateArticle',
  data(){
    return {
        selected: null,
        options: [
          { value: null, text: '文章類別' },
          { value: 's', text: '心得' },
          { value: 'a', text: '心情' },
          { value: 'b', text: '轉貼' },
          { value: 'w', text: '新聞' },
          { value: 'a', text: '自介' },
          { value: 'b', text: '提問' },
         
        ],
        value: ["疾病名稱"]
      
    }
  },
  
  methods:{
    onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      }
    ,onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.email = ''
        this.form.name = ''
        this.form.food = null
        this.form.checked = []
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
      }
     
  }
 
  
}
</script>