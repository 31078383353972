<template>
  
        
  
<ul class="list-group list-group-flush">
  <li class="list-group-item active">疾病交流</li>
  <li class="list-group-item">精華收錄</li>
  <li class="list-group-item">暫時心情分享</li>
  <li class="list-group-item">Porta ac consectetur ac</li>
  <li class="list-group-item">Vestibulum at eros</li>
</ul>
  


   
     
</template>

<script>
//import Header from './Header.vue'

export default {
  name: 'SideBarContent',
 
  
}
</script>