<template>

<div>


  <!-- <b-button 
      :class="visible ? null : 'collapsed'"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="collapseLogin"
      @click="visible = !visible"
  variant="primary" > -->
<b-button v-b-toggle.collapseLogin class="m-1">
    <span class="when-open"> <b-icon icon="box-arrow-in-up" variant="warning"></b-icon></span>
    <span class="when-closed"> <b-icon icon="pencil-fill" variant="warning"></b-icon> </span> 
    提問與分享
  </b-button>
  <b-collapse id="collapseLogin" v-model="visible">
        <!-- Content here -->
        <b-card bg-variant="dark" text-variant="white" title="登入或註冊">
    <b-card-text>
        歡迎加入我們的討論空間，發文前請登入或註冊成為網站會員！
        
    </b-card-text>

<div class="accordion" role="tablist">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-1 variant="info">請登入</b-button>
      </b-card-header>
      <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <CreateLogin />
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="info">請註冊</b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
        <b-card-body>
             
         <CreateSign />
        </b-card-body>
      </b-collapse>
    </b-card>
    
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-3 variant="info">請發文</b-button>
      </b-card-header>
      <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
        <b-card-body>
         <CreateArticle />
        </b-card-body>
      </b-collapse>
    </b-card>
    


  </div>
            <!-- <div class="card card-body">
            <CreateLogin />

          
                <b-button v-b-toggle.collapseSign class="m-1">請註冊成為會員</b-button>

              

                <b-collapse id="collapseSign">
                    <CreateSign />
                </b-collapse>
            </div> -->
    
        </b-card>
  </b-collapse>

</div>


   
     
</template>

<script>
import CreateSign from './CreateSign.vue'
import CreateLogin from './CreateLogin.vue'
import CreateArticle from './CreateArticle.vue'

export default {
  name: 'CardSign',
  components:{
      CreateLogin,
      CreateSign,
      CreateArticle
 
  }
  ,data(){
      return {
        //   visible: false
      }
  }
//   methods: {
//       collapseSign: function(){
//        alert(this.classObject)  
//        if (this.classObject == "display-none") {
//            this.classObject = "display-block";
//        }else{
//            this.classObject = "display-none";
//        }
//     }
//  }
 
  
}
</script>